.Spacer {
    flex-shrink: 0;
}

.Spacer.sm {
    flex-basis: 4px;
}

.Spacer.md {
    flex-basis: 8px;
}

.Spacer.lg {
    flex-basis: 16px;
}
