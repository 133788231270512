.TextArea {
    border: 1px solid var(--border-form-field);
    padding: 8px;
    background-color: transparent;
    color: var(--text-primary);
    border-radius: var(--border-radius-input);
}

.TextArea:disabled {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
}

.TextArea::placeholder {
    color: var(--text-placeholder);
}

.TextArea.dirty {
    border-color: var(--border-dirty);
    color: var(--text-dirt);
}

.TextArea.error {
    border-color: var(--border-error);
    color: var(--text-error);
}

.TextArea.error::placeholder {
    color: var(--text-placeholder-error);
}
