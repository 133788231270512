.DatacenterEditor-rackTypeSamples {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-4);
    margin-top: var(--spacing-4);
    justify-items: center;
}

.DatacenterEditor-rackTypeSamples img {
    border-radius: var(--border-radius-base);
    height: 320px;
}
