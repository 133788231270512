.SegmentGroup {
    --segment-spacing-vertical: var(--spacing-5);
    --segment-spacing-horizontal: calc(2 * var(--spacing));
    display: flex;
    flex-direction: column;
}

.SegmentGroup.horizontal {
    display: flex;
    flex-direction: row;
}

.SegmentGroup > .Segment + .Segment {
    margin-left: 0;
    margin-top: var(--segment-spacing-vertical);
}

.SegmentGroup.horizontal > .Segment + .Segment {
    margin-left: var(--segment-spacing-horizontal);
    margin-top: 0;
}

.SegmentGroup.horizontal > .Segment {
    flex-basis: 0;
    flex-grow: 1;
}

.SegmentGroup.compact {
    --segment-spacing-vertical: var(--spacing-4);
}

.SegmentGroup.expanded {
    --segment-spacing-vertical: var(--spacing-7);
}

.Segment {
    flex-grow: 1;
}

.Segment.fluid {
    width: 100%;
}

@media screen and (max-width: 425px) {
    .SegmentGroup.horizontal {
        flex-direction: column;
    }

    .SegmentGroup.horizontal > .Segment + .Segment {
        margin-left: 0;
        margin-top: var(--segment-spacing-vertical);
    }
}
