.AddressVerificationStatus {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    background-color: var(--background-float);
    padding: var(--spacing-4);
    border-radius: var(--border-radius);
}

.AddressVerificationStatus.pending {
    opacity: 0.2;
}

.AddressVerificationStatus.verified {
    color: var(--text-success);
}
