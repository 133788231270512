.RoundedLine {
    height: 8px;
    border-radius: 6px;
    background-color: var(--text-secondary);
    width: 100%;
    opacity: 0.6;

    animation-duration: 1s;
    animation-name: color-change;
    animation-iteration-count: infinite;
}

.RoundedLine + .RoundedLine {
    margin-top: 24px;
}

.RoundedLine:nth-child(2n) {
    animation-delay: -0.25s;
}

.RoundedLine:nth-child(3n) {
    animation-delay: -0.5s;
}

@keyframes color-change {
    0% {
        background-color: var(--text-secondary);
    }

    50% {
        background-color: white;
    }

    100% {
        background-color: var(--text-secondary);
    }
}
