.PartOrderLineItemsEditor-lineItem {
    box-sizing: border-box;
    margin-bottom: var(--spacing-4);
}

.PartOrderLineItemsEditor-lineItem .Removable-actions {
    margin-top: var(--spacing); /* align with form inputs */
}

.PartOrderLineItemsEditor-actions {
    margin-top: var(--spacing);
}
