.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: var(--length);
    min-width: var(--length);
    font-size: var(--font-size-button);
    font-weight: var(--font-weight-button);
    cursor: pointer;

    color: var(--color);
    background-color: var(--background-color);
    border: var(--border-width) solid var(--border-color);

    transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);

    --length: var(--length-button);
    --divider-width: 1px;
    --border-width: 1px;
    --hover-filter: brightness(105%);
}

.Button.small {
    --length: var(--length-button-small);
}

.Button.fluid {
    width: 100%;
    min-width: unset;
}

.Button.rounded {
    --border-radius: var(--border-radius-button);
    border-radius: var(--border-radius);
}

.Button.pill {
    --border-radius: var(--border-radius-pill);
    border-radius: var(--border-radius);
}

/* The case where the accessory is another button, apply same border radius */
.Button.rounded > .Button,
.Button.pill > .Button {
    border-radius: 0;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.Button > button {
    position: relative;
    padding: 0 12px;
    height: 100%;
    width: inherit;
    flex: 1 1 100%;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    background-color: transparent;
    border: none;
    cursor: inherit;
    border-radius: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: inherit;
    outline: inherit;
}

/* Kind */

.Button.action {
    --color: white;
    --color-active: white;
    --background-color: var(--primary);
    --background-color-hover: var(--primary);
    --border-color: var(--primary);
}

.Button.primary {
    --color: var(--text-blue);
    --color-active: var(--blue-40);
    --background-color: transparent;
    --background-color-hover: var(--background-transparent-blue-hover);
    --border-color: var(--border-blue);
}

.Button.dismiss {
    --color: white;
    --color-active: white;
    --background-color: transparent;
    --background-color-hover: var(--background-transparent-black-hover);
    --border-color: var(--border);
}

.Button.danger {
    --color: white;
    --color-active: white;
    --background-color: var(--background-danger);
    --background-color-hover: var(--background-danger);
    --border-color: var(--background-danger);
}

.Button.attention {
    --color: white;
    --color-active: white;
    --background-color: var(--background-attention);
    --background-color-hover: var(--background-attention);
    --border-color: var(--background-attention);
}

/* States */

.Button:hover {
    filter: var(--hover-filter);
    background-color: var(--background-color-hover);
}

.Button:active {
    position: relative;
    z-index: 1;
    color: var(--color-active);
    background-color: var(--background-color-hover);
}

.Button.disabled,
.ButtonAccessoryLauncher.disabled {
    cursor: not-allowed;
    opacity: var(--opacity-disabled);
}

.Button.loading {
    cursor: wait;
    filter: sepia(50%);
}

/* Accessory */

.Button.withAccessory {
    justify-content: space-between;
    margin-right: -1px;
}

.Button.withAccessory {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
