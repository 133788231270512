.Badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding: 0 8px;
    cursor: default;
    text-transform: uppercase;
    font-size: var(--font-size-meta);
    font-weight: var(--font-weight-heavy);
    border-radius: var(--border-radius-base);
}

.Badge.standard {
    background-color: var(--background-inverted);
    color: var(--text-primary-inverted);
}

.Badge.attention {
    background-color: var(--red-70);
    color: var(--text-primary);
}

.Badge.subdued {
    background-color: var(--background-float);
    color: var(--text-primary);
}

.Badge.unique {
    background-color: var(--background-purple);
    color: var(--text-primary);
}

.BadgeGroup .Badge:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.BadgeGroup .Badge:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.BadgeGroup .Badge:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.BadgeGroup .Badge:only-child {
    border-radius: var(--border-radius-base);
}
