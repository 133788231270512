.AppHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    --width-logo: 120px;
    --margin-logo: 8px;
    padding: var(--spacing-4) 0;
    background: var(--background-prod);
}

.AppHeader .Container {
    width: 100%;
    display: flex;
    max-width: var(--max-width-app);
    padding: 0 var(--padding-app-edge-horizontal);
}

.AppHeader-logo {
    width: var(--width-logo);
    height: 48px;
    margin-right: var(--margin-logo);
    margin-left: -12px; /* Align with navigation bar */
}

.AppHeader-balance {
    width: var(--width-logo);
    margin-left: var(--margin-logo);
}

.AppHeader-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.AppHeader.authenticated .AppHeader-header {
    justify-content: space-between;
}

.AppHeader-logout {
    margin-left: 16px;
    cursor: pointer;
}

.AppHeader-user {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.AppHeader-identity {
    margin-left: 8px;
}

.AppHeader.dev .AppHeader-env {
    background: var(--background-dev);
}

.AppHeader.test .AppHeader-env {
    background: var(--background-test);
}

.AppHeader-env {
    padding: var(--spacing-2) var(--spacing-3);
    border-radius: var(--border-radius-base);
    font-style: italic;
}

/* Vertically Stacked */

@media screen and (max-width: 645px) {
    .AppHeader .Container {
        flex-direction: column;
        align-items: stretch;
    }

    .AppHeader .Header .Header-left {
        justify-content: flex-start;
    }

    .AppHeader-logo {
        margin: -12px;
    }

    .AppHeader-user {
        margin-left: 0;
        align-self: flex-start;
    }

    .AppHeader-header {
        justify-content: flex-start;
    }
}
