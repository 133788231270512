.Grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    --column-spacing-vertical: 0;
    --column-spacing-horizontal: calc(1.5 * var(--spacing));
}

.GridColumn + .GridColumn {
    margin-top: var(--column-spacing-vertical);
    margin-left: var(--column-spacing-horizontal);
}

@media screen and (max-width: 425px) {
    .Grid.stackable {
        flex-direction: column;

        --column-spacing-vertical: calc(1.5 * var(--spacing));
        --column-spacing-horizontal: 0;
    }
}
