.PartnerFormsList {
    box-shadow: none;
    padding: var(--spacing);
    /* background-color: var(--background-darker); */
    width: 100%;

    --spacing-between-sections: var(--spacing);
    --spacing-between-items: 8px;
    --spacing-below-heading: 16px;
}

.PartnerFormsList-header {
    text-align: right;
}

.PartnerFormsList-header .PartnerFormsList-lastUpdatedLabel {
    font-size: var(--font-size-detail);
    color: var(--text-primary);
}

/* PartnerFormsListSection */
.PartnerFormsListSection + .PartnerFormsListSection {
    margin-top: var(--spacing-between-sections);
}

.PartnerFormsListSection-header {
    margin-bottom: var(--spacing-below-heading);
    font-size: var(--font-size-section-header);
    color: var(--text-primary);
    display: inline-block;
}

.PartnerFormsListSection-name:nth-child(2) {
    margin-left: 8px;
}

.PartnerFormsListSection-loading {
    color: var(--text-subdued);
}

.PartnerFormsListSection li + li {
    margin-top: var(--spacing-between-items);
}

/* PartnerFormsListItem */
.PartnerFormsListItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 32px;
    margin-left: calc(-1 * var(--spacing-4));
    margin-right: calc(-1 * var(--spacing-4));
    padding-left: var(--spacing-4);
    padding-right: var(--spacing-4);
    border-radius: var(--border-radius-base);
}

.PartnerFormsListItem.active {
    background-color: var(--background-link-inverted);
}

.PartnerFormsListItem-header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.PartnerFormsListItem-link {
    overflow: hidden;
}

.PartnerFormsListItem-name:nth-child(2) {
    margin-left: 8px;
}

.PartnerFormsListItem-accessory {
    margin-left: 8px;
}

.PartnerFormsListItem-timestamp {
    color: var(--text-secondary);
    white-space: nowrap;
}

.PartnerFormsListItem-timestamp.active {
    color: inherit;
}
