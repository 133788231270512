.Login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-grow: 1;
    position: relative;
    max-width: var(--max-width-login);
}

.Login__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-self: center;
    padding: var(--spacing);
    background-color: var(--background-lighter);
    border-radius: var(--border-radius-base);
    box-shadow: 2px 2px 10px var(--box-shadow-color-base);
}

.Login__code {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: var(--spacing);
}

.Login__sso {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: var(--spacing);
}
