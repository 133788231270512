.ApplianceBrowser {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-radius: 6px;
    margin-bottom: 24px;
    --height: 60px;
    --height-many-extra: 8px;
    --background: rgba(255, 255, 255, 0.1);
    --background-hover: rgba(255, 255, 255, 0.15);
    --opacity-disabled: 0.45;
}

/* List */
.ApplianceBrowser-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    flex-grow: 1;
    background-color: var(--background);
    padding: 0 8px;
}

.ApplianceBrowser-list:not(.many) {
    border-radius: var(--border-radius-base);
}

.ApplianceBrowser-listItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    margin-top: 8px;
    position: relative;
}

.ApplianceBrowser-listItem.active:before {
    content: "";
    position: absolute;
    width: var(--size);
    height: var(--size);
    background-color: var(--text-link-active);
    border-radius: 50%;
    top: -2px;
    --size: 6px;
}

.ApplianceBrowser-listItem:not(.active) .ApplianceInfo {
    opacity: 0.6;
}

.ApplianceBrowser-actionLink {
    margin-top: 4px;
}

/* Nav */

.ApplianceBrowser-DirectionalNavigationControl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    cursor: pointer;
    background-color: transparent;
    background-color: var(--background);
}

.ApplianceBrowser-DirectionalNavigationControl.left {
    margin-right: 1px;
    border-top-left-radius: var(--border-radius-base);
    border-bottom-left-radius: var(--border-radius-base);
}

.ApplianceBrowser-DirectionalNavigationControl.right {
    margin-left: 1px;
    border-top-right-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
}

.ApplianceBrowser-DirectionalNavigationControl:hover {
    background-color: var(--background-hover);
}

.ApplianceBrowser-DirectionalNavigationControl.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: var(--opacity-disabled);
}
