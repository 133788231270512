.Meta {
    color: var(--text-meta);
    font-size: var(--font-size-meta);
    font-weight: var(--font-weight-50);
    text-transform: uppercase;
    letter-spacing: 0.08em;
    margin-bottom: 8px;
}

.Meta.inline {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: unset;
}
