.OptionBar {
    --border-color: var(--border-grey);
    --border-width: 1px;
    position: relative;
    overflow: hidden;
    display: flex;
}

.OptionBar-group {
    display: inherit;
    width: 100%;
}

.OptionBar-option {
    flex-grow: 1;
    cursor: pointer;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    transition: all 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.OptionBar-option:hover {
    background-color: var(--background-transparent-black-hover);
    color: var(--text-secondary-hover);
}

.OptionBar-option {
    border: var(--border-width) solid var(--border-color);
}

.OptionBar-option:first-child {
    border-top-left-radius: var(--border-radius-base);
    border-bottom-left-radius: var(--border-radius-base);
}

.OptionBar-option:last-child {
    border-top-right-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
}

.OptionBar-option:not(:first-child) {
    margin-left: -1px;
}

.OptionBar-option.active {
    color: var(--text-primary);
    border-color: var(--border-active);
    position: relative;
}

.OptionBar.dirty .OptionBar-option.active {
    border-color: var(--border-dirty);
}
