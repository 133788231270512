.TextInput {
    border: 1px solid var(--border-form-field);
    padding: 8px;
    background-color: transparent;
    color: var(--text-primary);
    border-radius: var(--border-radius-input);
}

.TextInput:disabled {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
}

.TextInput::placeholder {
    color: var(--text-placeholder);
}

.TextInput.dirty {
    border-color: var(--border-dirty);
    color: var(--text-dirty);
}

.TextInput.error {
    border-color: var(--border-error);
    color: var(--text-error);
}

.TextInput.error::placeholder {
    color: var(--text-placeholder-error);
}
