.Nav {
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: center;
    background-color: var(--background-lighter);
    white-space: nowrap;
    width: 100%;
    border-top: 1px solid var(--divider);
}

.Nav .Container {
    display: flex;
    max-width: var(--max-width-app);
}

/* vertical */
.Nav .Container {
    flex-direction: column;
    padding: var(--spacing);
}

.Nav__header {
    flex-grow: 1;
    margin-bottom: var(--spacing);
}

.Nav .Nav__item + .Nav__item {
    margin-top: 8px;
}

/* horizontal */
@media screen and (min-width: 560px) {
    .Nav.horizontal {
        flex-direction: row;
        padding: 0;
    }

    .Nav.horizontal .Container {
        flex-direction: row;
        align-items: center;
        padding: 0 var(--padding-app-edge-horizontal);
    }

    .Nav.horizontal .Nav__list {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .Nav.horizontal .Nav__header {
        margin-bottom: unset;
        margin-right: var(--spacing);
    }

    .Nav.horizontal .Nav__item + .Nav__item {
        margin-top: unset;
        margin-left: var(--spacing);
    }
}
