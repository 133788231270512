.AppliancesEditor-networkConfigs .NetworkConfigForm {
    display: none;
}

.AppliancesEditor-networkConfigs .NetworkConfigForm.active {
    display: initial;
}

.AppliancesEditor-errorMessage {
    margin-top: 16px;
}

.AppliancesEditor-saveButton {
    margin-top: 16px;
}

.AppliancesEditor-copySelector {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.AppliancesEditor-copySelector .Dropdown {
    margin-left: 8px;
}
