.MissingInfo {
    display: flex;
    align-items: center;
    color: var(--text-subdued);
    font-weight: var(--font-weight-medium);
    border-radius: var(--border-radius-base);
}

.MissingInfo.sm {
    font-size: var(--font-size-detail);
}

.MissingInfo.md {
    font-size: var(--font-size-base);
}

.MissingInfo.lg {
    font-size: var(--font-size-notice);
}

.MissingInfo.filled {
    background: var(--background-float);
}

.MissingInfo.padded {
    padding: var(--spacing);
}

.MissingInfo.center {
    justify-content: center;
}

.MissingInfo.fluid {
    height: 100%;
    width: 100%;
}
