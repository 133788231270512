.PartnerFormHeader {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: var(--spacing-vertical) 0;
}

.PartnerFormHeader__left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.PartnerFormHeader__accessory {
    display: inline-block;
    min-width: 228px;
}

.PartnerFormHeader .BadgeGroup {
    display: flex;
}

@media screen and (max-width: 425px) {
    .PartnerFormHeader {
        flex-direction: column;
    }

    .PartnerFormHeader__accessory {
        padding-left: 16px;
        padding-top: 8px;
        align-self: flex-end;
    }
}
