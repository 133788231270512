:root {
    --netflix: #e50914;

    --black: #000;
    --white: #fff;

    --grey-10: #f9f9fa;
    --grey-20: #ededf0;
    --grey-30: #d7d7db;
    --grey-40: #b1b1b3;
    --grey-50: #737373;
    --grey-60: #4a4a4f;
    --grey-70: #38383d;
    --grey-80: #2a2a2a;
    --gray-85: #141414;
    --grey-90: #0c0c0c;

    --blue-50: #79b1f1;
    --blue-60: #2990ff;
    --blue-70: #1b69c1;
    --blue-80: #0e4888;
    --blue-90: #0b284b;

    --green-50: #a2ecd5;
    --green-60: #54c5a1;
    --green-70: #3a9177;
    --green-80: #1b5342;
    --green-90: #0d3125;

    --yellow-50: #fff690;
    --yellow-60: #ffed17;
    --yellow-70: #ffe800;
    --yellow-80: #d2be00;
    --yellow-90: #615700;

    --orange-50: #ffdb91;
    --orange-60: #ffbc25;
    --orange-70: #ffa500;
    --orange-80: #d28700;
    --orange-90: #623e00;

    --red-50: #f8c4c4;
    --red-60: #ec4553;
    --red-70: #d61b13;
    --red-80: #a0170b;
    --red-90: #3e0a04;

    --purple-50: #c48adb;
    --purple-60: #ab4fcd;
    --purple-70: #9224bb;
    --purple-80: #670688;
    --purple-90: #3f0255;
}
