.Value {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Value.inline {
    flex-direction: row;
    align-items: center;
}
