.NetworkConfigForm .remote-network-configs {
    margin-top: var(--spacing-3);
}

.NetworkConfigForm .description {
    color: var(--text-secondary);
    font-weight: var(--font-weight-heavy);
    margin: 0 0 var(--spacing-4) 0;
}

.NetworkConfigForm .ip-type {
    margin: var(--spacing-4) 0 var(--spacing-5) 0;
}
