.PartnerFormContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-basis: 0;
    flex-grow: 1;
    min-height: 0;

    --background: var(--background-lighter);
    --spacing-vertical: var(--spacing);
    --spacing-horizontal: var(--spacing);
    --spacing-content-vertical: var(--spacing);
}

.PartnerFormContainerMaster {
    width: 100%;
    overflow-y: auto;
    flex: 1 1 0;
    min-width: 360px;
    background-color: var(--background);
    margin-right: var(--spacing);
    box-shadow: 2px 2px 10px var(--box-shadow-color-base);
    border-radius: var(--border-radius-base);
}

.PartnerFormContainerDetail {
    display: flex;
    flex-direction: column;
    flex: 2 1 0;
    overflow-y: auto;
    position: relative;
    background-color: var(--background);
    min-width: 60%;
    max-width: 60%;
    box-shadow: 2px 2px 10px var(--box-shadow-color-base);
    border-radius: var(--border-radius-base);
}

.PartnerFormContainer__backButton {
    display: none;
}

@media screen and (max-width: 1280px) {
    .PartnerFormContainerMaster {
        margin-right: 0;
    }

    .PartnerFormContainerDetail {
        width: 100%;
        max-width: unset;
        min-width: 0;
    }

    .PartnerFormContainer__backButton {
        display: inline-flex;
        align-self: flex-start;
        padding-left: var(--spacing-horizontal);
        padding-top: var(--spacing);
    }

    .PartnerFormContainer.showDetail .PartnerFormContainerMaster {
        display: none;
    }

    .PartnerFormContainer:not(.showDetail) .PartnerFormContainerDetail {
        display: none;
    }
}
