.Icon {
    display: inline-flex;
    align-items: center;
}

.Icon.xxs {
    font-size: 0.6em;
}

.Icon.clickable {
    cursor: pointer;
    padding: 4px;
}
