.FormField {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px; /* Enough space for error message */
}

.FormField.hidden {
    display: none;
}

.FormField.horizontal {
    flex-direction: row;
    align-items: center;
}

.FormField.fluid {
    width: 100%;
}

.FormField.fluid .FormField-content {
    width: 100%;
}

.FormField.sm .FormField-content {
    width: 180px;
}

.FormField.md .FormField-content {
    width: 240px;
}

.FormField.lg .FormField-content {
    width: 300px;
}

.FormField.horizontal .FormField-label {
    min-width: 60%;
    max-width: 60%;
}

.FormField.horizontal .FormField-content {
    width: auto;
    flex-grow: 1;
    flex-shrink: 0;
    max-width: 40%;
}

.FormField-requiredIndicator {
    color: var(--required);
    padding-left: 4px;
}

.FormField-content {
    display: flex;
    flex-direction: column;
}

.FormField-childContainer {
    display: flex;
}

.FormField-childContainer > *:first-child {
    flex: 1;
}

.FormField-childContainer.withAccessory > *:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.FormField-childContainer.withAccessory > *:nth-child(2) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
}

.FormField-content select {
    width: 100%;
    text-overflow: ellipsis;
}

.FormField-error {
    display: flex;
    justify-content: flex-end;
    padding-top: 4px;
    font-size: 0.96em;
    position: absolute;
    white-space: nowrap;
    top: 100%;
    right: 0;
    color: var(--text-error);
    font-size: var(--font-size-form-field-error);
}

.FormField-label {
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: var(--font-size-form-label);
    color: var(--text-form-label);
}

.FormField-notes {
    color: var(--text-secondary);
    margin-bottom: 8px;
}
