:root {
    --title-30: 18px;
    --title-20: 16px;
    --title-10: 14px;

    --body-10: 10px;
    --body-20: 12px;
    --body-30: 14px;
    --body-40: 16px;

    --font-weight-30: 300;
    --font-weight-50: 500;
    --font-weight-60: 600;
    --font-weight-70: 700;
}
