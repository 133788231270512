@import url("./palette.css");
@import url("./typography.css");

:root {
    --primary: var(--blue-70);
    --required: var(--red-70);
    --success: var(--green-70);
    --attention: var(--orange-70);
    --error: var(--red-70);
    --divider: var(--grey-70);

    --background: var(--grey-90);
    --background-lighter: var(--gray-85);
    --background-lightest: var(--grey-80);
    --background-darker: var(--grey-90);
    --background-float: var(--background-lightest);
    --background-popup: var(--background-lightest);
    --background-inverted: white;
    --background-purple: var(--purple-90);
    --background-orange: var(--orange-90);
    --background-green: var(--green-90);
    --background-red: var(--red-90);
    --background-transparent-black-hover: #ffffff0d;
    --background-transparent-blue-hover: #3292ff26;
    --background-success: var(--background-green);
    --background-error: var(--background-red);
    --background-attention: var(--orange-80);
    --background-danger: var(--red-80);
    --background-prod: var(--background-lighter);
    --background-dev: linear-gradient(to right, #1743a7, #da3434);
    --background-test: linear-gradient(to right, #17a757, #7c34da);
    --background-link-inverted: var(--blue-70);

    --text-white: var(--grey-10);
    --text-blue: var(--blue-60);
    --text-green: var(--green-60);
    --text-orange: var(--orange-60);
    --text-red: var(--red-60);
    --text-purple: var(--purple-60);

    --text-primary: var(--text-white);
    --text-primary-inverted: var(--black);
    --text-secondary: var(--grey-40);
    --text-secondary-hover: var(--grey-30);
    --text-subdued: var(--grey-50);
    --text-placeholder: var(--grey-50);
    --text: var(--text-primary);
    --text-inverted: var(--text-primary-inverted);
    --text-dirty: var(--text-primary);
    --text-success: var(--text-green);
    --text-meta: var(--grey-40);
    --text-form-label: var(--grey-30);
    --text-attention: var(--text-orange);
    --text-error: var(--text-red);
    --text-placeholder-error: var(--red-50);
    --text-link: var(--text-blue);
    --text-link-hover: var(--blue-50);
    --text-link-active: var(--text-primary);

    --border-blue: var(--blue-60);
    --border-purple: var(--purple-60);
    --border-orange: var(--orange-60);
    --border-red: var(--red-60);
    --border-grey: var(--grey-60);
    --border-white: var(--grey-10);

    --border: var(--border-grey);
    --border-active: var(--grey-30);
    --border-form-field: var(--grey-50);
    --border-dirty: var(--border-purple);
    --border-attention: var(--border-orange);
    --border-error: var(--border-red);

    --font-size-header: var(--title-30);
    --font-size-subheader: var(--title-20);
    --font-size-section-header: var(--title-10);
    --font-size-notice: var(--body-40);
    --font-size-base: var(--body-30);
    --font-size-link: var(--body-30);
    --font-size-action-link: var(--body-20);
    --font-size-detail: var(--body-20);
    --font-size-hint: var(--body-20);
    --font-size-button: var(--body-20);
    --font-size-meta: var(--body-10);
    --font-size-form-label: var(--body-30);
    --font-size-form-field-error: var(--font-size-detail);

    --font-weight-base: var(--font-weight-30);
    --font-weight-medium: var(--font-weight-50);
    --font-weight-heavy: var(--font-weight-60);
    --font-weight-bold: var(--font-weight-70);
    --font-weight-headers: var(--font-weight-heavy);
    --font-weight-link: var(--font-weight-medium);
    --font-weight-button: var(--font-weight-medium);

    --line-height-base: var(--title-30);

    --length-button-base: 40px;
    --length-button-small: 36px;
    --length-button: var(--length-button-base);
    --max-width-app: 1280px;
    --min-width-app: 320px;
    --padding-app-edge-horizontal: 24px;

    --box-shadow-color-base: rgb(0, 0, 0, 0.3);

    --border-radius-base: 6px;
    --border-radius-button: 4px;
    --border-radius-input: 4px;
    --border-radius-pill: 1000px; /* something large */

    --opacity-disabled: 0.45;

    --z-index-float: 10;
    --z-index-popup: 100;
    --z-index-modal: 200;

    --spacing-2: 2px;
    --spacing-3: 4px;
    --spacing-4: 8px;
    --spacing-5: 16px;
    --spacing-6: 24px;
    --spacing-7: 36px;
    --spacing-8: 48px;

    --spacing: var(--spacing-5);

    --max-width-login: 360px;
    --max-width-org-picker: 360px;
}

@media screen and (max-width: 768px) {
    :root {
        --spacing: var(--spacing-5);
        --padding-app-edge-horizontal: 16px;
    }
}

@media screen and (max-width: 425px) {
    :root {
        --spacing: var(--spacing-4);
        --padding-app-edge-horizontal: 8px;
    }
}
