@import url("~normalize.css");
@import url("~@octools/netflix-font/css/netflix.css");
@import url("./theme.css");

body {
    font-family: "Netflix Sans", Helvetica, sans-serif;
    font-size: var(--font-size-base);
}

a {
    color: var(--blue);
    text-decoration: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul li {
    margin-left: 0;
}
