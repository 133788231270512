.Removable {
    position: relative;
    display: flex;
    align-items: center;
}

.Removable.align-top {
    align-items: flex-start;
}

.Removable.align-bottom {
    align-items: flex-end;
}

.Removable.fluid {
    width: 100%;
}

.Removable-content {
    flex-grow: 1;
}

.Removable-actions {
    margin-left: 16px;
}
