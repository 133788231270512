.AdditionalRecipientsEditor-recipientListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.AdditionalRecipientsEditor-recipientListItem.new {
    text-decoration: underline;
    text-decoration-color: var(--border-dirty);
}
