.AppFooter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppFooter .Container {
    width: 100%;
    max-width: var(--max-width-app);
    padding: 0 var(--padding-app-edge-horizontal);
}
