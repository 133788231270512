.OcaReturnDetail {
    border-radius: var(--border-radius-base);
    padding: 8px;
    background: linear-gradient(130deg, transparent, var(--color-1)),
        radial-gradient(circle at top left, var(--color-2), transparent);
    display: inline-flex;
    flex-direction: column;
    flex-shrink: 0;
    position: relative;
}

.OcaReturnDetail + .OcaReturnDetail {
    margin-left: var(--spacing);
}

.OcaReturnDetail-icon {
    position: absolute;
    top: var(--spacing);
    right: var(--spacing);
}

.OcaReturnDetail.storage {
    --color-1: var(--blue-70);
    --color-2: var(--purple-80);
}

.OcaReturnDetail.flash {
    --color-1: var(--purple-70);
    --color-2: var(--red-70);
}

.OcaReturnDetail.global {
    --color-1: var(--green-70);
    --color-2: var(--blue-80);
}
