/* Actions */
.ActionLink {
    display: inline-flex;
    align-items: center;
    font-size: var(--font-size-action-link);
}

.ActionLink.withBackground {
    padding: 4px;
    border-radius: var(--border-radius-base);
}

.ActionLink.withBackground:hover {
    background-color: var(--background-transparent-blue-hover);
}

.ActionLink.disabled {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
}

.ActionLink .Icon {
    margin-right: 4px;
}
