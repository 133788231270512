.PartnerForm {
    background-color: var(--background);
    display: flex;
    flex-direction: column;
}

.PartnerForm-head {
    padding-left: var(--spacing-horizontal);
    padding-right: var(--spacing-horizontal);
}

.PartnerForm-content {
    margin: var(--spacing-content-vertical) 0;
    min-height: 640px;
}
