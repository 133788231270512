.PartnerFormContent {
    display: flex;
    flex-direction: column;
}

.PartnerFormContent > .Header {
    margin-bottom: var(--spacing-vertical);
}

.PartnerFormContent-subtitle {
    color: var(--text-secondary);
}
