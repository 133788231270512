.DoneIndicator {
    display: inline-flex;
    align-items: center;
    color: var(--text-success);
}

.DoneIndicator-text {
    font-size: var(--font-size-notice);
    margin-left: 8px;
}
