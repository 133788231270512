.HelpIcon {
    display: inline-flex;
    align-items: center;
    cursor: help;
    font-size: var(--font-size-hint);
}

.HelpIcon.spaced-left {
    margin-left: var(--spacing-3);
}
