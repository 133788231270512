.Tab {
    --divider-thickness: 1px;
    --divider-color: var(--divider);
    --border-radius: 4px;
    --padding-menuItem-vertical: 16px;
    --padding-menuItem-horizontal: 24px;
}

.Tab-menuList {
    display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
}

.Tab-menuList:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--divider-thickness);
    background-color: var(--divider-color);
}

.Tab-menuItem {
    box-sizing: border-box;
    display: flex;
    padding: var(--padding-menuItem-vertical) var(--padding-menuItem-horizontal);
    border: var(--divider-thickness) solid transparent;
    border-bottom-color: var(--divider-color);
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    z-index: 1;
}

.Tab-menuItem:first-child {
    border-top-left-radius: 0;
    border-left: none;
}

.Tab-menuItem.active {
    border-color: var(--divider-color);
    border-bottom-color: var(--background);
}

.Tab .TabPane {
    display: none;
}

.Tab .TabPane.active {
    display: flex;
}

.TabPane {
    padding: var(--spacing-horizontal);
}
