.MenuItem {
    font-size: 1.1428em;
    font-weight: var(--font-weight-link);
}

.MenuItem.withStatus {
    display: flex;
    align-items: center;
}

.MenuItem.withStatus .Icon {
    margin-right: 8px;
}