.Body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin-top: var(--spacing);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 640px;
}

@media screen and (min-width: 1280px) {
    .Body {
        max-width: var(--max-width-app);
        margin-top: 0;
        padding: var(--spacing) var(--padding-app-edge-horizontal);
    }
}
