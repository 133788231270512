.Form {
    --form-field-spacing: 20px;
    --form-button-spacing: 24px;
    --form-inter-button-spacing: 8px;
}

.Form.compact {
    --form-field-spacing: 16px;
    --form-button-spacing: 20px;
}

.Form:not(.horizontal) .FormField:not(.hidden) + .FormField:not(.hidden) {
    margin-top: var(--form-field-spacing);
}

.Form .FormActions {
    margin-top: var(--form-button-spacing);
}

.Form .FormActions .Button + .Button {
    margin-left: var(--form-inter-button-spacing);
}

.Form.compact .FormActions {
    display: flex;
}

.Form.compact .FormActions .Button {
    flex: 1;
}

@media screen and (min-width: 425px) {
    .Form.horizontal {
        display: flex;
    }

    .Form.horizontal .FormField + .FormField {
        margin-top: unset;
        margin-left: var(--form-field-spacing);
    }
}
