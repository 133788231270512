.ButtonAccessoryLauncher {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    margin-left: calc(-1 * var(--divider-width));

    --accessory-launcher-width: 40px;
    --offset-accessory: 16px;
    --border-radius: var(--border-radius-button);
}

.ButtonAccessoryLauncher-button {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    overflow: hidden;
}

/* divider */
.ButtonAccessoryLauncher:before {
    content: "";
    position: absolute;
    left: 0;
    width: var(--border-width);
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.ButtonAccessory {
    position: absolute;
    background-color: var(--background-popup);
    border-radius: 6px;
    top: calc(100% + var(--offset-accessory));
    right: 0;
    padding: 16px;
    z-index: var(--z-index-popup);
}

/* triangle */
.ButtonAccessory:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-bottom: var(--size) solid var(--background-popup);
    bottom: calc(100% - 1px);
    right: 8px;

    --size: 10px;
}
