.App {
    font-weight: var(--font-weight-base);
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: var(--background);
    color: var(--text);
    min-width: var(--min-width-app);
}

.App,
.App * {
    box-sizing: border-box;
}

.App > .Body > .Nav {
    --nav-width: 160px;
    --nav-margin: 16px;
    width: 100%;
    margin-bottom: var(--spacing);
    align-self: center;
}

@media screen and (max-width: 645px) {
    .App {
        display: block;
    }

    .App > .Body {
        display: block;
    }
}
