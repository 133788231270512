.OrgPicker {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    max-width: var(--max-width-org-picker);
}

.OrgPicker__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--spacing);
    background-color: var(--background-lighter);
    border-radius: var(--border-radius-base);
    box-shadow: 2px 2px 10px var(--box-shadow-color-base);
}

.OrgPicker__loading {
    display: flex;
    justify-content: center;
}

.OrgPicker__form {
    display: flex;
    flex-direction: column;
    padding: var(--spacing);
}

.OrgPicker__form .Button {
    width: 100%;
}
