.SudoToggle {
    width: 100%;
    display: grid;
    grid-template-columns: auto 160px;
    gap: var(--spacing-4);
    margin-bottom: var(--spacing-5);
    padding: var(--spacing-5);
    border: 1px solid var(--border-grey);
    align-items: center;
    border-radius: var(--border-radius-base);
}

.SudoToggle.active {
    border-color: var(--border-attention);
}

/* vertically stacked */
@media screen and (max-width: 645px) {
    .SudoToggle {
        grid-template-columns: unset;
        grid-template-rows: auto auto;
        height: auto;
        margin-bottom: var(--spacing-4);
        border-left: none;
        border-right: none;
    }
}
