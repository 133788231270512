.AddressEditor {
    display: flex;
    flex-direction: column;
}

.AddressEditor .Button {
    margin-left: var(--spacing-3);
    height: 34px;
}

.AddressEditor .Button .Icon {
    margin-right: var(--spacing-3);
}

.AddressEditor-useQuickAddress {
    display: block;
    margin-bottom: var(--spacing-3);
}

.AddressEditor-selection {
    margin-bottom: 16px;
    margin-top: 16px;
    padding-left: 24px;
    border-left: 1px solid var(--divider);
}
