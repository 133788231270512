.SaveAndSubmit {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-end;
    --spacing: 8px;
}

.SaveAndSubmit-saveStatus {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: calc(100% + 4px);
    font-size: var(--font-size-hint);
    color: var(--text-secondary);
    z-index: var(--z-index-popup);
}

.SaveAndSubmit-submitStatus {
    z-index: var(--z-index-popup);
}

.SaveAndSubmit > .Button {
    width: 120px;
}

.SaveAndSubmit-errorMessage {
    box-shadow: 2px 2px 10px var(--box-shadow-color-base);
}

.SaveAndSubmit-saveError {
    margin-top: 8px;
}

.SaveAndSubmit-submitError {
    position: absolute;
    right: 0;
    top: calc(100% + var(--spacing));
    min-width: 240px;
}
