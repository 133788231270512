.ApplianceInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing);
    cursor: pointer;
    border-radius: var(--border-radius-base);
    --spacing: 8px;
    transition: background-color 0.05s ease-in-out;
    width: 88px;
}

.ApplianceInfo:hover {
    background-color: var(--background-hover);
}

.ApplianceInfo-applianceTypeBadge {
    display: flex;
    background-color: var(--background-inverted);
    color: var(--text-inverted);
    padding: 4px 8px;
    border-radius: 24px;
    text-transform: uppercase;
    font-size: var(--font-size-meta);
    margin-top: var(--spacing);
}

.ApplianceInfo-applianceTypeLabel {
    margin-left: var(--spacing);
}
