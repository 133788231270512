.ChooseOrCreateContact {
    display: flex;
    flex-direction: column;
}

.ChooseOrCreateContact .Button {
    margin-left: var(--spacing-3);
    height: 34px;
}

.ChooseOrCreateContact .Button .Icon {
    margin-right: var(--spacing-3);
}

.ChooseOrCreateContact-content.isCreatingContact {
    margin-bottom: var(--spacing-5);
}

.ChooseOrCreateContact-selection {
    margin-top: var(--spacing-5);
    margin-bottom: var(--spacing-5);
    padding-left: var(--spacing-6);
    border-left: 1px solid var(--divider);
}

.ChooseOrCreateContact-description {
    margin-top: var(--spacing-4);
    color: var(--text-secondary);
}

.ChooseOrCreateContact-portal-link {
    margin-top: var(--spacing-4);
}
