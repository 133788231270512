.Dropdown {
    position: relative;
    background-color: transparent;
    color: var(--text-primary);
    cursor: pointer;
    height: 34px;
    display: flex;
    align-items: center;
    border-color: var(--border-form-field);
}

.Dropdown > select {
    height: inherit;
    max-width: 100%;
    background-color: inherit;
    border-color: inherit;
    border-width: 1px;
    border-style: solid;
    border-radius: var(--border-radius-base);
    color: inherit;
    padding: 0 4px;
    min-width: 0;
}

/* NOTE: FF allows styling of disabled options, so don't target them here. */
.Dropdown option:not(:disabled),
.Dropdown optgroup:not(:disabled) {
    color: initial;
    background: initial;
}

.Dropdown.disabled select {
    opacity: var(--opacity-disabled);
    cursor: not-allowed;
}

/* States */
.Dropdown.placeholder {
    color: var(--text-placeholder);
}

.Dropdown.dirty {
    border-color: var(--border-dirty);
    color: var(--text-dirty);
}

.Dropdown.error {
    border-color: var(--border-error);
    color: var(--text-error);
}

.Dropdown.placeholder.error {
    color: var(--text-placeholder-error);
}

/* Dropdown - Clear Selection */

.Dropdown-clearSelection.Icon {
    transition: all 0.1s ease-in-out;
    opacity: 0;
    height: 100%;
    padding: 0;
    width: 0;
    pointer-events: none;
}

.Dropdown-clearSelection.Icon.show {
    opacity: 0.85;
    width: initial;
    padding-left: 8px;
    pointer-events: all;
}

.Dropdown:hover .Dropdown-clearSelection.Icon.show:hover {
    opacity: 1;
}
