.Link {
    color: var(--text-link);
    font-weight: var(--font-weight-link);
    cursor: pointer;
    font-size: var(--font-size-link);
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
}

.Link:hover {
    color: var(--text-link-hover);
}

.Link:active {
    filter: brightness(115%);
}

.Link.active {
    color: var(--text-link-active);
}

.Link.disabled {
    opacity: 0.5;
    pointer-events: none;
}
