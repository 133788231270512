.Divider {
    display: flex;
    flex-direction: inherit;
    background-color: inherit;
    flex-basis: calc(var(--spacing-5) * 2);
    align-items: stretch;
    justify-content: center;
    align-self: stretch;
    position: relative;
}

.Divider::after {
    content: "";
    flex-basis: 1px;
    background-color: var(--divider);
}

.Divider-text {
    position: absolute;
    padding: var(--spacing-4);
    background-color: inherit;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    font-weight: 500;
}
