.ButtonGroup {
    white-space: nowrap;
}

.ButtonGroup.fluid {
    width: 100%;
    display: flex;
}

.ButtonGroup .Button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ButtonGroup .Button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.ButtonGroup .Button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.ButtonGroup .Button:not(:first-child) {
    margin-left: calc(-1 * var(--border-width));
}
