.Header {
    padding: 8px 0;
    display: inline-block;
    overflow: hidden;
}

.Header-left {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.Header h1,
.Header h2,
.Header h3,
.Header h4,
.Header h5,
.Header h6 {
    font-weight: var(--font-weight-headers);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.Header-subtitle {
    margin-top: var(--spacing-3);
    color: var(--text-subdued);
}

.Header p {
    margin-bottom: 0;
}

.Header.fluid {
    width: 100%;
}

.Header.textAlignCenter {
    text-align: center;
}

.Header.justifyContentCenter .Header-left {
    justify-content: center;
}
