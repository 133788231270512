.Message {
    display: flex;
    align-items: center;
    font-size: 1em;

    --border-radius: 0;
    --color: var(--text-primary);
    --background-color: transparent;
    border-radius: var(--border-radius);
    color: var(--color);
    background-color: var(--background-color);
}

.Message.noWrap {
    white-space: nowrap;
}

.Message.success {
    --color: var(--text-success);
    font-weight: var(--font-weight-50);
}

.Message.error {
    --color: var(--text-error);
    font-weight: var(--font-weight-50);
}

.Message.block {
    padding: 8px;
    min-height: 36px;

    --border-radius: 4px;
    --color: var(--text-primary);
    --background-color: var(--background-float);
}

.Message.highContrast.block.error {
    border: 1px solid var(--color);
    font-weight: var(--font-weight-heavy);

    --color: var(--text-error);
    --background-color: var(--background-error);
}

.Message-icon {
    display: flex;
    align-items: center;
}

.Message.warning .Message-icon {
    color: var(--attention);
}

.Message.error .Message-icon {
    color: var(--error);
}

.Message.clickable {
    cursor: pointer;
}

.Message.clickable:hover {
    filter: brightness(105%);
}

.Message.withIcon .Message-text {
    margin-left: 8px;
}

.Message.alignCenter .Message-text {
    text-align: center;
}

.Message:not(.block) + .Message:not(.block) {
    margin-top: 8px;
}

.Message.block + .Message.block {
    /* Make divider the color of parent element background */
    border-top: 1px solid var(--background);
}

.Message.group.block:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.Message.group.block:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.Message-list {
    margin-left: 24px;
    list-style: disc;
}

.Message-list li {
    margin-top: 4px;
}

.Message-dismiss {
    margin-left: var(--spacing);
    align-self: flex-start;
}
