.Tooltip__content {
    background-color: var(--background-popup);
    padding: var(--spacing-4);
    border-radius: var(--border-radius-base);
    border: 1px solid var(--border);
}

.Tooltip__target {
    display: flex;
    align-items: center;
    justify-content: center;
}
